/**
 *
 * Custom function
 *
 */ 

(function($) {

    function allClick() { 
        var clickbox = $('.allclick');
        clickbox.each(function() {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function(event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function() {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function() {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    function disableList() {
        $('.section-banner .item, .section-banner-gastronomia .item').each(function() {
            if($(this).hasClass('disabled')) {
                var elem = $(this).closest('.allclick');
                
                elem.unbind(allClick())
                    .click(false)
                    .css('cursor', 'default')
                    .removeClass('allclick');
            }
        });
    }

    /*
     *  Funzioni per PhotoSwiper
     */
    function addImageSizeForPhotoSwiper() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function(index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    function initPhotoSwiper(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        galleryItems.each(function(index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            myImage = {
                src: $(this).find('a').attr('href'),
                w: width,
                h: height,
                msrc: $(this).find('img').attr('src')
            };
             
            pswpItems.push(myImage);
        });


        galleryItems.each(function(index) {
            $(this).click(function(evt) {
                evt.preventDefault();
                openPhotoSwipe(pswpItems, index);
            });
        });
    }

    function openPhotoSwipe(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    // function initializeGmap() {
    //     $('*[data-location]').each(function() {
    //         var map = $(this),
    //             address = map.attr('data-location');
    //         map.setGMap({
    //             address: address
    //         });
    //     });
    // }

    var original_loadGMap = $.edt_loadGmap;
    var original_initalizeGmap = $.edt_initializeGmap;

    $.edt_loadGmap = function() {
        $.getScript('https://www.google.com/jsapi', function() {
            if(url.key) {
                var map_params = 'language=' + url.lingua + '&key=' + url.key;
            }
            else {
                var map_params = 'language=' + url.lingua;
            }
            google.load('maps', '3', {
                //other_params: 'sensor=true&language=it&key=AIzaSyArLivgSbUesgyOVzjYQwQmtt11cScI_FE',
                other_params: map_params,
                callback: function() {
                    original_initalizeGmap();
                    if ($("body").hasClass("page-template-template-punti-di-interesse")) {

                        initializeMap();

                        $(".owl-scrolling-container .prev").click(function(){
                            var index = $(".locations-list li").find("a.active").attr("data-location-index");
                            if(index == 0){
                                index = $(".locations-list li").length-1;
                            }else{
                                index--;
                            }
                            console.log(index)
                            $(".locations-list li").find("[data-location-index='" + index + "']").trigger('click');
                            //$(".location-slideshow .owl-carousel").trigger('owl.goTo', index);
                        });
                        $(".owl-scrolling-container .next").click(function(){
                            var index = $(".locations-list li").find("a.active").attr("data-location-index");
                            if(index == ($(".locations-list li").length-1)){
                                index = 0;
                            }else{
                                index++;
                            }
                            console.log(index)
                            $(".locations-list li").find("[data-location-index='" + index + "']").trigger('click');
                            //$(".location-slideshow .owl-carousel").trigger('owl.goTo', index);
                        });
                    }
                }
            });
        });
    }

    /* 
     *  Funzione di inizializazione della mappa "Punti di interesse"
     */

    function initializeMap() {

        var locations = new Array();
        var arrayMarker = new Array();
        var infoWindow = new google.maps.InfoWindow();

        var directionsService = new google.maps.DirectionsService();
        var directionsDisplay = new google.maps.DirectionsRenderer();


        //Instazio la mappa centrandola con le coordinate della prima localitò
        var map = new google.maps.Map(document.getElementById('map_canvas'), {
            center: new google.maps.LatLng($(".locations-list li:first-child a").data("location-lat"), $(".locations-list li:first-child a").data("location-lon")),
            zoom: 15,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles:
			[
			{
			featureType: 'poi.business',
			elementType: 'labels',
			stylers:
			[
			{
			visibility: 'off'
			}
			]
			}
			]
		});

		// inizializzo la mappa con il primo indicatore impostato
		google.maps.event.addListenerOnce(map, 'tilesloaded', function(){
			$(".locations-list li:first a").click();
		});

        //Ciclo il menù con i punti di interesse per leggere gli attributi data dalla DOM
        $(".locations-list li").each(function(){

            var name = ($('a', this).data("location-name"));
            var lat = ($('a', this).data("location-lat"));
            var lon = ($('a', this).data("location-lon"));
            var index = ($('a', this).data("location-index"));

            //Eventuale icona custom per il marker
            //var iconMarket = 'http://cdn1.buuteeq.com/aspx/shared/images/location/Balloon/1/A.png';

            //Creazione del marker per un punto di interesse
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat,lon),
                map: map,
                //icon: iconMarket,
                title: name
            });

            //Inserisco il marker appena creato in un vettore da unare successivamente
            arrayMarker[index] = marker;

            //Listener che al click sul marker apre l'infoWindow con il nome (title del post)
            marker.addListener('click', function() {
                infoWindow.setContent(marker.title);
                infoWindow.open(map, marker);
            });


            //Evento al click dell'elenco puntato con i punti di interesse
            $('a', this).click(function(event) {

                //Setto la classe active sulla voce cliccata
                $(".locations-list li a").each(function(){
                    $(this).removeClass('active');
                });
                $(this).addClass('active');

                //Mi leggo l'indice della località
                var index = $(this).data("location-index");

                //Mi carico il relativo marker 
                currentMarker = arrayMarker[index];

                //Centro la mappa sul marker
                map.setCenter(currentMarker.getPosition());

                //Apro infoWindow sul marker corrente
                infoWindow.setContent(currentMarker.title);
                infoWindow.open(map, currentMarker);

                //Cambio il nome del punto di interesse nella DOM
                name = $(this).data("location-name");
                $("#location-title").html(name)

                //Visualizzo il contenuto testuale del punto di interesse nascondendo tutti gli altri (sono già stampati e resi invisibili)
                $("#info .item").addClass('hide');
                $("#info .item[data-index='"+index+"']").removeClass('hide');

                //Imposto la select per generare il percorso con la partenza dalla località selezionata
                $("#directions select[name='from_direction']").prop("selectedIndex", index); 

                return false;
            });

        });


        //Gestione freccette di navigazione, controllo l'indice del menù delle località e eseguo il trigger del click sulla voce precedente o successiva
        $(".scrolling.prev").click(function(event) {
            var index = $(".locations-list li a.active").data("location-index");
            if(index == 0){
                index = $(".locations-list li").length-1;
            }else{
                index--;
            }
            $(".locations-list li a[data-location-index='"+index+"']").trigger("click");
        });

        $(".scrolling.next").click(function(event) {
            var index = $(".locations-list li a.active").data("location-index");
            if(index == ($(".locations-list li").length-1)){
                index = 0;
            }else{
                index++;
            }
            $(".locations-list li a[data-location-index='"+index+"']").trigger("click");
        });

        //Gestione del menù per selezionare il tipo di trasporto (Auto, Piedi, Bicicletta o Bus)
        $(".location-slideshow .mode_type").each(function(){
            $(this).click(function(event) {
                var index = $(this).data("index");
                $("#directions .mode_type").removeClass("selected");
                $(this).addClass("selected");
            });
        });

        //Form per stampare il percorso tra i due punti di interesse settati sulle select
        $(".location-slideshow .search-directions").click(function(event) {

            var travel = $(".location-slideshow .mode_type.selected").data("value")

            var from = $("#directions select[name='from_direction']").val().split(",");
            var to = $("#directions select[name='to_direction']").val().split(",");

            var request = {
                origin: new google.maps.LatLng(from[0], from[1]),
                destination: new google.maps.LatLng(to[0], to[1]),
                travelMode: google.maps.DirectionsTravelMode[travel]
            };

            directionsDisplay.setMap(map);
            directionsDisplay.setPanel(document.getElementById('map_route'));

            directionsService.route(request, function(response, status){
                
                if (status == google.maps.DirectionsStatus.OK){
                    directionsDisplay.setDirections(response);
                }
                
            });  

        });

    }

    function scrollAnimations(desktopScrollTop, desktopScrollBol) {
        var header = $('#js-header'),
            menu = $('#js-fixed-menu'),
            desktopFixedMenu = $('#js-desktopFixedMenu'),
            headerH = $('#js-header').outerHeight(), // Height compreso di margini e bordi
            windowWidth = $(window).width();
            booking = $('.booking-container');

        if ($('body').is('.is-mobile')) {
            var menuH = $('#js-fixed-menu').innerHeight(), // Height senza margini e bordi
                scrollValue = headerH - menuH;
                
            // header.css('height', headerH);

            $(window).scroll(function() {
                scrolltop = $(window).scrollTop();

                if ( scrolltop >= scrollValue) {

                    menu.addClass("scroll");
                
                } else {
                    menu.removeClass("scroll");
                }            
            });

        } else {
            $(window).scroll(function() {
                scrolltop = $(window).scrollTop();

                if ( scrolltop > 0 ) {
                    header.addClass("scroll");
                } else {
                    header.removeClass("scroll");
                }
            });
        }
    }


    $.edt_responsiveMenu = function(viewportSize) {

        $('.main-navigation').find('a[href="#"]').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
            });
        });

        var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");

        $('.main-navigation').find('a[href="#"]').each(function() {
            $(this).click(function(event) {
                $('ul.sub-menu').removeClass('open');
                $(this).next().toggleClass('open');
            });
        });

        $("#js-menu-offcanvas-button-open").click(function() {
            menuPrimaryOffcanvas.addClass("offcanvas-open");
        });

        $("#js-menu-offcanvas-button-close").click(function() {
            menuPrimaryOffcanvas.removeClass("offcanvas-open");
        });
    }

    function accordion() {
        var accItem = document.getElementsByClassName("accordionItem");
        var accHD = document.getElementsByClassName("accordionItemHeading");

        if (accItem.length == 0) return;

        for (i = 0; i < accHD.length; i++) {
            accHD[i].addEventListener("click", toggleItem, false);
        }
        
        function toggleItem() {
            var itemClass = this.parentNode.className;
            for (i = 0; i < accItem.length; i++) {
                accItem[i].className = "accordionItem closeItem";
            }
            if (itemClass == "accordionItem closeItem") {
                this.parentNode.className = "accordionItem openItem";
            }

            var headerHeight = $('#js-header').outerHeight() + 50;

            $('html, body').animate({
                scrollTop: $(this).offset().top - headerHeight,
            }, 400);
        }
    }


    $(document).ready(function() {

        disableList();
        scrollAnimations(280, true);
        accordion();
        $.edt_responsiveMenu();

        $('.review p').collapser({
            mode: 'words',
            truncate: 30,
            showText: '+',
            hideText: '-',
            controlBtn: 'btnMore',
        });
        
		// stampo il nome del pacchetto selezionato dall'utente
		// nel campo note del form contatti/preventivo
		// $('[data-pacchetto]').on('click', function(e){
		// 	var thisCTA = $(e.currentTarget);
		// 	var form = $('form[data-mvs-type="form-contatti"]');

		// 	if( thisCTA.data('pacchetto') != undefined && form.length > 0 ) {
		// 		form.find('[name="notes"]').val( jQuery('h1').text() + " / " + thisCTA.data('pacchetto'));
		// 	}
		// });



        // if( window.screen.width <= 767 ) {
        //     $('#')   
        // }

        var urlParams = new URLSearchParams(window.location.search);

        if(urlParams.has("camera") && urlParams.has("pacchetto")) {
            var camera = urlParams.get("camera"); 
            var pacchetto = urlParams.get("pacchetto");

            if($("#efb-432").length) {
                $("#efb-432").text( url.camera + ': ' + camera + ', ' + url.pacchetto + ': ' + pacchetto);
            }
        }
    });


    $(window).load(function() {
        initPhotoSwiper(".gallerySlideshow .zoom");
        
		// evito di usare equalHeights su mobile (dove gli elementi in genere sono sempre uno sotto l'altro)
		if( window.screen.width > 768 ) {
			$('.fixedHeight').equalHeights();
			$('.fixedHeight-2').equalHeights();
			$('.section-banner .item-container-content, .section-banner-gastronomia .item-container-content').equalHeights();
			// $('#section-offerte.offert-list .offers-slideshow .item').equalHeights();
			$('#section-offerte.offert-list .offers-slideshow .item .box-offer').equalHeights();
			$('#section-offerte.offert-list .offers-slideshow .item .box-offer .description').equalHeights();
			$('#section-offerte.offert-list .offers-slideshow .item .box-offer .title').equalHeights();
            
		}

    });

    $(window).resize(function() {


    });

})(jQuery);
